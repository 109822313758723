<!-- Header -->
<app-header></app-header>

<!-- Gallery -->
<div class="animacion">
  <img src="../../../assets/gallery/linea1.png" class="linea" id="l1" alt="" />
  <img src="../../../assets/gallery/globo.png" class="globo" alt="" />
  <div class="titulos" id="t1">
    <b> C<br />l<br />a<br />s<br />s<br />r<br />o<br />o<br />m<br />s </b>
  </div>
  <div class="titulos" id="t2">
    <b> P<br />l<br />a<br />y<br />g<br />r<br />o<br />u<br />n<br />d </b>
  </div>
  <div class="titulos" id="t3">
    <b> O<br />u<br />r<br /><br />P<br />e<br />t<br />s </b>
  </div>
  <img src="../../../assets/gallery/linea2.png" class="linea" id="l2" alt="" />
  <img src="../../../assets/gallery/linea3.png" class="linea" id="l3" alt="" />
  <img
    src="../../../assets/gallery/lineaFinal.png"
    class="linea"
    id="l4"
    alt=""
  />
</div>

<div class="divPrincipal">
  <div class="m-0 row justify-content-center divCarousel">
    <img src="../../../assets/gallery/marco.png" class="marco" alt="" />
    <div
      id="carouselClassrooms"
      class="carousel carousel-light slide w-75 col-auto text-center"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators ds-none d-xxl-block">
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>

        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="5"
          aria-label="Slide 6"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="6"
          aria-label="Slide 7"
        ></button>

        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="7"
          aria-label="Slide 8"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="8"
          aria-label="Slide 9"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="9"
          aria-label="Slide 10"
        ></button>

        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="10"
          aria-label="Slide 11"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="11"
          aria-label="Slide 12"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="12"
          aria-label="Slide 13"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="13"
          aria-label="Slide 14"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="14"
          aria-label="Slide 15"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="15"
          aria-label="Slide 16"
        ></button>

        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="16"
          aria-label="Slide 17"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="17"
          aria-label="Slide 18"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselClassrooms"
          data-bs-slide-to="18"
          aria-label="Slide 19"
        ></button>
      </div>
      <div class="carousel-inner carouselImg">
        <!----- Baby's Room d-sm-none----->
        <div class="carousel-item active" data-bs-interval="1000">
          <img
            src="/assets/gallery/classrooms/Baby's_Room/IMG_6889.jpg"
            class="d-block img-fluid"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c1">Baby's Room</div>
        </div>
        <div class="carousel-item" data-bs-interval="2000">
          <img
            src="/assets/gallery/classrooms/Baby's_Room/IMG_6890.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c1">Baby's Room</div>
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <img
            src="/assets/gallery/classrooms/Baby's_Room/IMG_6891.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c1">Baby's Room</div>
        </div>
        <!----- Before_and_after_schoolers ----->
        <div class="carousel-item" data-bs-interval="4000">
          <img
            src="/assets/gallery/classrooms/Before_and_after_schoolers/IMG_6902.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c2">Before and after schoolers</div>
        </div>
        <div class="carousel-item" data-bs-interval="5000">
          <img
            src="/assets/gallery/classrooms/Before_and_after_schoolers/IMG_6903.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c2">Before and after schoolers</div>
        </div>
        <div class="carousel-item" data-bs-interval="6000">
          <img
            src="/assets/gallery/classrooms/Before_and_after_schoolers/IMG_6904.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c2">Before and after schoolers</div>
        </div>
        <div class="carousel-item" data-bs-interval="7000">
          <img
            src="/assets/gallery/classrooms/Before_and_after_schoolers/IMG_6905.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c2">Before and after schoolers</div>
        </div>
        <!----- Early PreK ----->
        <div class="carousel-item" data-bs-interval="8000">
          <img
            src="/assets/gallery/classrooms/Early_PreK/IMG_6892.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c3">Early PreK</div>
        </div>
        <div class="carousel-item" data-bs-interval="9000">
          <img
            src="/assets/gallery/classrooms/Early_PreK/IMG_6893.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c3">Early PreK</div>
        </div>
        <div class="carousel-item" data-bs-interval="10000">
          <img
            src="/assets/gallery/classrooms/Early_PreK/IMG_6894.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c3">Early PreK</div>
        </div>
        <!----- PreK ----->
        <div class="carousel-item" data-bs-interval="11000">
          <img
            src="/assets/gallery/classrooms/PreK/IMG_6895.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c4">PreK</div>
        </div>
        <div class="carousel-item" data-bs-interval="12000">
          <img
            src="/assets/gallery/classrooms/PreK/IMG_6896.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c4">PreK</div>
        </div>
        <div class="carousel-item" data-bs-interval="13000">
          <img
            src="/assets/gallery/classrooms/PreK/IMG_6897.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c4">PreK</div>
        </div>
        <div class="carousel-item" data-bs-interval="14000">
          <img
            src="/assets/gallery/classrooms/PreK/IMG_6900.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c4">PreK</div>
        </div>
        <div class="carousel-item" data-bs-interval="15000">
          <img
            src="/assets/gallery/classrooms/PreK/IMG_6899.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c4">PreK</div>
        </div>
        <div class="carousel-item" data-bs-interval="16000">
          <img
            src="/assets/gallery/classrooms/PreK/IMG_6901.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c4">PreK</div>
        </div>
        <!----- Toddlers ----->
        <div class="carousel-item" data-bs-interval="17000">
          <img
            src="/assets/gallery/classrooms/Toddlers/IMG_6908.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c5">Toddlers</div>
        </div>
        <div class="carousel-item" data-bs-interval="18000">
          <img
            src="/assets/gallery/classrooms/Toddlers/IMG_6909.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c5">Toddlers</div>
        </div>
        <div class="carousel-item" data-bs-interval="19000">
          <img
            src="/assets/gallery/classrooms/Toddlers/IMG_6910.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
          <div class="classroom" id="c5">Toddlers</div>
        </div>
        <div class="carousel-caption caption">
          <div>
            <p>
              <b
                >We offer the best classrooms to guarantee the children's
                comfort, providing them with a good learning while having fun</b
              >
            </p>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselClassrooms"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselClassrooms"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>

  <div class="m-0 row justify-content-center divCarousel">
    <img
      src="/assets/gallery/marco.png"
      class="marco d-block"
      alt=""
      loading="lazy"
    />
    <div
      id="carouselPlayground"
      class="carousel carousel-light slide w-75 col-auto text-center"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators ds-none d-xxl-block">
        <button
          type="button"
          data-bs-target="#carouselPlayground"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselPlayground"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselPlayground"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div class="carousel-inner carouselImg">
        <div class="carousel-item active" data-bs-interval="10000">
          <img
            src="/assets/gallery/playground/IMG_6881.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="carousel-item" data-bs-interval="2000">
          <img
            src="/assets/gallery/playground/IMG_6883.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <img
            src="/assets/gallery/playground/IMG_6886.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="carousel-caption caption">
          <div>
            <p>
              <b
                >We have beautiful spaces for children to play outdoors and have
                fun with other children</b
              >
            </p>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselPlayground"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselPlayground"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>

  <div class="m-0 row justify-content-center divCarousel">
    <img
      src="/assets/gallery/marco.png"
      class="marco d-block"
      alt=""
      loading="lazy"
    />
    <div
      id="carouselPets"
      class="carousel carousel-light slide w-75 col-auto text-center"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators ds-none d-xxl-block">
        <button
          type="button"
          data-bs-target="#carouselPets"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselPets"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselPets"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div class="carousel-inner carouselImg">
        <div class="carousel-item active" data-bs-interval="10000">
          <img
            src="/assets/gallery/pets/mascota1.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="carousel-item" data-bs-interval="2000">
          <img
            src="/assets/gallery/pets/mascota2.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="/assets/gallery/pets/mascota3.jpg"
            class="d-block"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="carousel-caption caption">
          <div>
            <p>
              <b
                >Pets are also very important as these teach children
                responsibility and sensitivity for animals</b
              >
            </p>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselPets"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselPets"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>

<!--Footer-->
<app-footer [screen]="3"></app-footer>
