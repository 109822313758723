import { Component, DoCheck, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus, send } from 'emailjs-com';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { errorMessage, successDialog } from 'src/app/functions/alerts';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent implements OnInit, DoCheck {
  age: Number;
  status: Boolean;
  inscriptionForm: FormGroup;
  jobForm: FormGroup;

  public sendEmailInscription(e: Event) {
    if (this.inscriptionForm.valid) {
      e.preventDefault();
      emailjs
        .sendForm(
          'service_1r05kx8',
          'template_xvjf18n',
          e.target as HTMLFormElement,
          'user_ksTTkXYQ6OasvN1zdZBcp'
        )
        .then(
          (result: EmailJSResponseStatus) => {
            successDialog('Email Sent');
            this.inscriptionForm.reset();
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      this.inscriptionForm.markAllAsTouched();
    }
  }

  public sendEmailJob(e: Event) {
    if (this.jobForm.valid) {
      e.preventDefault();
      emailjs
        .sendForm(
          'service_1r05kx8',
          'template_8l3hwc7',
          e.target as HTMLFormElement,
          'user_ksTTkXYQ6OasvN1zdZBcp'
        )
        .then(
          (result: EmailJSResponseStatus) => {
            successDialog('Email Sent');
            this.jobForm.reset();
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      this.jobForm.markAllAsTouched();
    }
  }

  constructor(private fb: FormBuilder) {
    this.createForms();
  }

  ngOnInit(): void {
  }

  ngDoCheck() {
    if (this.age >= 18) {
      this.status = true;
    } else {
      this.status = false;
    }
  }

  createForms(): void {
    this.inscriptionForm = this.fb.group({
      fathersname: ['', [Validators.required]],
      fatherEmail: ['', [Validators.required, Validators.email]],
      kidsname: ['', [Validators.required]],
      kidsage: ['', [Validators.required]],
      note:['',[Validators.required]],
      phoneIns: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          Validators.maxLength(14),
        ],
      ],
    });

    this.jobForm = this.fb.group({
      nameJob: ['', [Validators.required]],
      emailJob: ['', [Validators.required, Validators.email]],
      age: [
        '',
        [
          Validators.required,
          Validators.compose([Validators.min(18), Validators.max(40)]),
        ],
      ],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          Validators.maxLength(14),
        ],
      ],
    });
  }
}
