<nav ngxHideOnScroll [propertyUsedToHide]="'top'" [valueWhenHidden]="'-200px'" [valueWhenShown]="'0px'" class="navbar navbar-expand-lg sticky-top" id="nav-spacing" (scroll)="onWindowScroll();" style="transition: top 0.3s ease-in-out;"> 
    <div class="container-fluid mx-auto">
        <a class="navbar-brand d-lg-none" href="#"><img src="assets/header/logo.png" alt="" width="80"></a>
        <button
            id="toggle"
            class="navbar-toggler navbar-toggler-right collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation">
           <i class="bi bi-list" style="color:#DC4B4B; font-size: 50px;"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav" style="justify-content: center;">
            <ul class="navbar-nav pullUp navbar-center">
                <li class="nav-item  mt-3 mt-lg-0" id="Home">
                    <a class="nav-link option a-home" routerLink='/Home' [routerLinkActive]="'option-home'" >Home</a>
                </li>
                <li class="nav-item  mt-3 mt-lg-0" id="ContactUs">
                    <a class="nav-link option a-c-us" routerLink='/Contact-us' [routerLinkActive]="'option-c-us'">Contact Us</a>
                </li>
                <li class="nav-item  mt-3 mt-lg-0" id="AboutUs">
                    <a class="nav-link option a-a-us" routerLink='/About-us' [routerLinkActive]="'option-a-us'">About Us</a>
                </li>
            </ul>
            <a class="d-none d-lg-block d-inline-flex p-2 " routerLink='/Home'><img src="assets/header/logo.png" alt="" width="150"></a>
            <ul class="navbar-nav pullUp navbar-center">
                <li class="nav-item  mt-3 mt-lg-0" id="Gallery">
                <a class="nav-link option a-gallery"   routerLink='/Gallery' [routerLinkActive]="'option-gallery'">Gallery</a>
                </li>
                <li class="nav-item  mt-3 mt-lg-0" id="Community">
                    <a class="nav-link option a-community"  routerLink='/Community' [routerLinkActive]="'option-community'">Community</a>
                </li>
                <li class="nav-item  mt-3 mt-lg-0" id="Activities">
                    <a class="nav-link option a-activities" routerLink='/Activities' [routerLinkActive]="'option-activities'">Activities</a>
                </li>
            </ul>
        </div>
    </div>
</nav>

