<!-- Header -->
<app-header></app-header>

<!-- Activities -->
<div>
  <img
    src="../../../assets/activities/activities.png"
    class="activities"
    alt=""
  />
</div>

<div class="postit">
  <img src="../../../assets/activities/postit.png" loading="lazy" />
  <div class="divMonths">
    <div><b>Special Dates</b></div>
    <hr style="height: 0.15rem" />
    <button class="btn" value="LaborDay" (click)="idButton = 1">
      Labor Day
    </button>
    <hr />
    <button class="btn" value="GraduationPreK" (click)="idButton = 2">
      Graduation of PreK
    </button>
    <hr />
    <button class="btn" value="Halloween" (click)="idButton = 3">
      Halloween
    </button>
    <hr />
    <button class="btn" value="MothersDay" (click)="idButton = 4">
      Mother's Day
    </button>
    <hr />
    <button class="btn" value="ValentinesDay" (click)="idButton = 5">
      Valentine's Day
    </button>
    <hr />
  </div>
</div>

<div *ngIf="idButton == 1">
  <div class="row justify-content-center divCarousel">
    <img
      src="../../../assets/activities/marco.png"
      class="marco"
      alt=""
      loading="lazy"
    />
    <div
      id="carouselLaborDay"
      class="carousel carousel-light slide w-75 col-auto text-center"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators ds-none d-xxl-block">
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="5"
          aria-label="Slide 6"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="6"
          aria-label="Slide 7"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="7"
          aria-label="Slide 8"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="8"
          aria-label="Slide 9"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="9"
          aria-label="Slide 10"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="10"
          aria-label="Slide 11"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="11"
          aria-label="Slide 12"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselLaborDay"
          data-bs-slide-to="12"
          aria-label="Slide 13"
        ></button>
      </div>
      <div class="carousel-inner carouselImg">
        <div class="carousel-item active">
          <img
            src="../../../assets/activities/LaborDay/20181009_090327.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_090343.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_090606.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_090951.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_091118.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_091653.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_091953.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_092330.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_092404.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_092427.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_092609.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_092859.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/LaborDay/20181009_093725.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-caption caption">
          <div><p>Labor Day</p></div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselLaborDay"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselLaborDay"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="idButton == 2">
  <div class="row justify-content-center divCarousel">
    <img
      src="../../../assets/activities/marco.png"
      class="marco"
      alt=""
      loading="lazy"
    />
    <div
      id="carouselGraduation"
      class="carousel carousel-light slide w-75 col-auto text-center"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators ds-none d-xxl-block">
        <button
          type="button"
          data-bs-target="#carouselGraduation"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselGraduation"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselGraduation"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselGraduation"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselGraduation"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselGraduation"
          data-bs-slide-to="5"
          aria-label="Slide 6"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselGraduation"
          data-bs-slide-to="6"
          aria-label="Slide 7"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselGraduation"
          data-bs-slide-to="7"
          aria-label="Slide 8"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselGraduation"
          data-bs-slide-to="8"
          aria-label="Slide 9"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselGraduation"
          data-bs-slide-to="9"
          aria-label="Slide 10"
        ></button>
      </div>
      <div class="carousel-inner carouselImg">
        <div class="carousel-item active">
          <img
            src="../../../assets/activities/Graduation of PreK/20190607_165516.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Graduation of PreK/20190607_171001.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Graduation of PreK/20190607_171106.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Graduation of PreK/20190607_171116.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Graduation of PreK/20190607_171127.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Graduation of PreK/20190607_171136.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Graduation of PreK/20190607_171152.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Graduation of PreK/20190607_171716.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Graduation of PreK/20190607_171951.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Graduation of PreK/20190607_172513.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-caption caption">
          <div><p>Graduation of PreK</p></div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselGraduation"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselGraduation"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="idButton == 3">
  <div class="row justify-content-center divCarousel">
    <img
      src="../../../assets/activities/marco.png"
      class="marco"
      alt=""
      loading="lazy"
    />
    <div
      id="carouselHalloween"
      class="carousel carousel-light slide w-75 col-auto text-center"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators ds-none d-xxl-block">
        <button
          type="button"
          data-bs-target="#carouselHalloween"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselHalloween"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselHalloween"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselHalloween"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselHalloween"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselHalloween"
          data-bs-slide-to="5"
          aria-label="Slide 6"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselHalloween"
          data-bs-slide-to="6"
          aria-label="Slide 7"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselHalloween"
          data-bs-slide-to="7"
          aria-label="Slide 8"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselHalloween"
          data-bs-slide-to="8"
          aria-label="Slide 9"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselHalloween"
          data-bs-slide-to="9"
          aria-label="Slide 10"
        ></button>
      </div>
      <div class="carousel-inner carouselImg">
        <div class="carousel-item active">
          <img
            src="../../../assets/activities/Halloween/20171031_185149.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Halloween/20191018_093704.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Halloween/SCN0003.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Halloween/SCN0005.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Halloween/SCN0006.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Halloween/SCN0011.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Halloween/SCN0012.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Halloween/SCN0014.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Halloween/SCN0025.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Halloween/MG_6391.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-caption caption">
          <div><p>Halloween</p></div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselHalloween"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselHalloween"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="idButton == 4">
  <div class="row justify-content-center divCarousel">
    <img
      src="../../../assets/activities/marco.png"
      class="marco"
      alt=""
      loading="lazy"
    />
    <div
      id="carouselMothersDay"
      class="carousel carousel-light slide w-75 col-auto text-center"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators ds-none d-xxl-block">
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="5"
          aria-label="Slide 6"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="6"
          aria-label="Slide 7"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="7"
          aria-label="Slide 8"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="8"
          aria-label="Slide 9"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="9"
          aria-label="Slide 10"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="10"
          aria-label="Slide 11"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="11"
          aria-label="Slide 12"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="12"
          aria-label="Slide 13"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="13"
          aria-label="Slide 14"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="14"
          aria-label="Slide 15"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="15"
          aria-label="Slide 16"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="16"
          aria-label="Slide 17"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselMothersDay"
          data-bs-slide-to="17"
          aria-label="Slide 18"
        ></button>
      </div>
      <div class="carousel-inner carouselImg">
        <div class="carousel-item active">
          <img
            src="../../../assets/activities/Mother'sDay/20190507_132832.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190507_132917.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190507_133141.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190507_141745.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190507_142139.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190507_142428.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190507_142447.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190507_142758.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190507_143332.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190509_133140.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190509_133352.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190509_134025.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190509_134413.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190509_134727.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190509_135043.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190509_135818.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190510_100409.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Mother'sDay/20190510_101454.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-caption caption">
          <div><p>Mother's Day</p></div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselMothersDay"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselMothersDay"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="idButton == 5">
  <div class="row justify-content-center divCarousel">
    <img
      src="../../../assets/activities/marco.png"
      class="marco"
      alt=""
      loading="lazy"
    />
    <div
      id="carouselValentinesDay"
      class="carousel carousel-light slide w-75 col-auto text-center"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators ds-none d-xxl-block">
        <button
          type="button"
          data-bs-target="#carouselValentinesDay"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselValentinesDay"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselValentinesDay"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselValentinesDay"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselValentinesDay"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselValentinesDay"
          data-bs-slide-to="5"
          aria-label="Slide 6"
        ></button>
      </div>
      <div class="carousel-inner carouselImg">
        <div class="carousel-item active">
          <img
            src="../../../assets/activities/Valentine'sDay/20200214_115553.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Valentine'sDay/20200214_115938.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Valentine'sDay/20200214_120830.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Valentine'sDay/20200214_120831.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Valentine'sDay/20200214_120832.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/activities/Valentine'sDay/20200214_121412.jpg"
            alt="..."
            loading="lazy"
          />
        </div>
        <div class="carousel-caption caption">
          <div><p>Valentine's Day</p></div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselValentinesDay"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselValentinesDay"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>

<div style="margin-top: 8%">
  <img
    src="../../../assets/activities/cuerda.png"
    class="w-100"
    loading="lazy"
  />
  <div class="flag">
    <img src="../../../assets/activities/purpleflag.png" loading="lazy" />
    <p>
      Throughout the year we carry out different activities to teach children
      the traditions in our country; in addition, these have the objective that
      they interact with other children in order that they have a better
      development, instill respect and improve their way of communicating with
      others.
    </p>
  </div>
</div>
<img
  class="butterfly1"
  src="../../../assets/activities/butterfly.png"
  loading="lazy"
/>
<img
  class="butterfly2"
  src="../../../assets/activities/butterfly.png"
  loading="lazy"
/>

<!--Footer-->
<app-footer [screen]="6"></app-footer>
