import { Component } from '@angular/core';
import {
  Event,

  NavigationStart,
  Router
} from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'childrensworld';
  loading = false;
  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          setTimeout(() =>{
            this.loading = true
          }, 2500 )
          break;
        }

        default: {
          break;
        }
      }
    });
  }


}
