<!-- Header -->
<app-header></app-header>
<div id="divContactUS">
  <div id="divMaps">
    <img
      src="/assets/contact-us/LocationBirds.png"
      alt="Location"
      style="width: 50%; height: 20%"
    />
    <br />
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d683.7994426766702!2d-105.93508202817418!3d32.89049759888386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86e050f081cfa297%3A0x5b476fed05dff908!2s2103%20E%201st%20St%2C%20Alamogordo%2C%20NM%2088310%2C%20EE.%20UU.!5e1!3m2!1ses-419!2smx!4v1621553141297!5m2!1ses-419!2smx"
      width="1000"
      height="400"
      style="border: 4px dashed gray; padding: 7px"
      allowfullscreen=""
      loading="lazy"
      id="google"
    >
    </iframe>
  </div>

  <div id="divCards">
    <!--Card Libreta rosa-->
    <div class="contenedor">
      <div class="libreta">
        <div class="card" style="width: 18rem; border: none">
          <img
            src="assets/contact-us/kids.png"
            class="card-img-top d-block mx-auto"
            alt="..."
            style="width: 128px; height: 128px"
          />
          <div class="card-body">
            <h5
              class="card-title"
              style="font-family: 'Fredoka One'; color: rgb(70, 70, 70)"
            >
              Inscriptions
            </h5>
            <p class="card-text" style="font-family: 'Varela Round'">
              Children’s World Child Development Center (CWCDC) enrolls children
              from ages 6 weeks to 12 years. We offer numerous programs which
              are all developmentally age-appropriate.
            </p>
            <button
              class="btn btn-primary moreinfo"
              id="moreinfo"
              style="
                width: 100%;
                background-color: #f78997;
                border: none;
                font-family: 'Fredoka One';
              "
              data-bs-toggle="modal"
              data-bs-target="#kidsInscription"
            >
              More info
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Card Libreta azul-->
    <div class="contenedor">
      <div class="libreta-azul">
        <div class="card" style="width: 18rem; border: none">
          <img
            src="assets/contact-us/enfermera.png"
            class="card-img-top d-block mx-auto"
            alt="..."
            style="width: 128px; height: 128px"
          />
          <div class="card-body">
            <h5
              class="card-title"
              style="font-family: 'Fredoka One'; color: rgb(70, 70, 70)"
            >
              Now taking job applications!
            </h5>
            <p class="card-text" style="font-family: 'Varela Round'">
              Do you like working with children? This is your chance !
            </p>
            <br /><br /><br /><br />
            <button
              class="btn btn-primary moreinfo-azul"
              id="moreinfo-azul"
              style="
                width: 100%;
                background-color: #87b2c6;
                border: none;
                font-family: 'Fredoka One';
              "
              data-bs-toggle="modal"
              data-bs-target="#jobApplication"
            >
              More info
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal Inscription-->
<div
  class="modal fade"
  id="kidsInscription"
  tabindex="-1"
  aria-labelledby="kidsInscriptionLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5
          class="modal-title"
          id="kidsInscriptionLabel"
          style="font-family: 'Fredoka One'"
        >
          Inscription
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form
          [formGroup]="inscriptionForm"
          (ngSubmit)="sendEmailInscription($event)"
        >
          <div class="mb-3">
            <label
              for="fname"
              class="form-label"
              style="font-family: 'Varela Round'"
              >Parent Name</label
            >
            <input
              type="text"
              class="form-control"
              formControlName="fathersname"
              id="fname"
              name="fathersname"
            />
            <div
              *ngIf="
                inscriptionForm.get('fathersname').errors &&
                inscriptionForm.get('fathersname').touched
              "
              class="invalid"
            >
              <p
                *ngIf="inscriptionForm.get('fathersname').hasError('required')"
                style="font-family: 'Varela Round'"
              >
                Required Field
              </p>
            </div>
          </div>
          <div class="mb-3">
            <label
              for="femail"
              class="form-label"
              style="font-family: 'Varela Round'"
              >Email address</label
            >
            <input
              type="email"
              class="form-control"
              id="femail"
              aria-describedby="emailHelp"
              formControlName="fatherEmail"
              name="fatherEmail"
            />
            <div
              *ngIf="
                inscriptionForm.get('fatherEmail').errors &&
                inscriptionForm.get('fatherEmail').touched
              "
              class="invalid"
            >
              <p
                *ngIf="inscriptionForm.get('fatherEmail').hasError('required')"
                style="font-family: 'Varela Round'"
              >
                Required Field
              </p>
              <p
                *ngIf="inscriptionForm.get('fatherEmail').hasError('email')"
                style="font-family: 'Varela Round'"
              >
                Invalid Email
              </p>
            </div>
          </div>
          <div class="mb-3">
            <label
              for="phoneIns"
              class="form-label"
              style="font-family: 'Varela Round'"
              >Phone number</label
            >
            <input
              type="tel"
              class="form-control"
              formControlName="phoneIns"
              name="phoneIns"
            />
            <div
              *ngIf="
                inscriptionForm.get('phoneIns').errors &&
                inscriptionForm.get('phoneIns').touched
              "
              class="invalid"
            >
              <p
                *ngIf="inscriptionForm.get('phoneIns').hasError('required')"
                style="font-family: 'Varela Round'"
              >
                Required Field
              </p>
              <p
                *ngIf="
                  inscriptionForm.get('phoneIns').errors?.pattern ||
                  inscriptionForm.get('phoneIns').hasError('minlength') ||
                  inscriptionForm.get('phoneIns').hasError('maxlength')
                "
              >
                Invalid phone number
              </p>
            </div>
          </div>
          <div class="mb-3">
            <label
              for="kname"
              class="form-label"
              style="font-family: 'Varela Round'"
              >Child(ren) name</label
            >
            <input
              type="text"
              class="form-control"
              formControlName="kidsname"
              id="kname"
              name="kidsname"
            />
            <div
              *ngIf="
                inscriptionForm.get('kidsname').errors &&
                inscriptionForm.get('kidsname').touched
              "
              class="invalid"
            >
              <p
                *ngIf="inscriptionForm.get('kidsname').hasError('required')"
                style="font-family: 'Varela Round'"
              >
                Required Field
              </p>
            </div>
          </div>
          <div class="mb-3">
            <label
              for="kage"
              class="form-label"
              style="font-family: 'Varela Round'"
              >Child(ren) Age</label
            >
            <input
              type="text"
              formControlName="kidsage"
              class="form-control"
              id="kage"
              name="kidsage"
            />
            <div
              *ngIf="
                inscriptionForm.get('kidsage').errors &&
                inscriptionForm.get('kidsage').touched
              "
              class="invalid"
            >
              <p
                *ngIf="inscriptionForm.get('kidsage').hasError('required')"
                style="font-family: 'Varela Round'"
              >
                Required Field
              </p>
            </div>
          </div>
          <div class="mb-3">
            <label
              for="note"
              class="form-label"
              style="font-family: 'Varela Round'"
              >Note:
            </label>
            <textarea
              name="note"
              class="form-control"
              formControlName="note"
              id="note"
              cols="20"
              rows="2"
            ></textarea>
            <div
              *ngIf="
                inscriptionForm.get('note').errors &&
                inscriptionForm.get('note').touched
              "
              class="invalid"
            >
              <p
                *ngIf="inscriptionForm.get('note').hasError('required')"
                style="font-family: 'Varela Round'"
              >
                Required Field
              </p>
            </div>
          </div>
          <button
            type="submit"
            id="submit"
            class="btn btn-primary"
            style="width: 100%; font-family: 'Fredoka One'"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<!--Modal Job Application-->
<div
  class="modal fade"
  id="jobApplication"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="font-family: 'Varela Round'">
      <div class="modal-header">
        <h5
          class="modal-title"
          id="exampleModalLabel"
          style="font-family: 'Fredoka One'"
        >
          Job application
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="jobForm" (ngSubmit)="sendEmailJob($event)">
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Name</label>
            <input
              type="text"
              class="form-control"
              formControlName="nameJob"
              name="nameJob"
            />
            <div
              *ngIf="
                jobForm.get('nameJob').errors && jobForm.get('nameJob').touched
              "
              class="invalid"
            >
              <p *ngIf="jobForm.get('nameJob').hasError('required')">
                Required Field
              </p>
            </div>
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label"
              >Email address</label
            >
            <input
              type="email"
              formControlName="emailJob"
              name="emailJob"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
            <div
              *ngIf="
                jobForm.get('emailJob').errors &&
                jobForm.get('emailJob').touched
              "
              class="invalid"
            >
              <p *ngIf="jobForm.get('emailJob').hasError('required')">
                Required Field
              </p>
              <p *ngIf="jobForm.get('emailJob').hasError('email')">
                Invalid Email
              </p>
            </div>
            <div id="emailHelp" class="form-text">
              We'll never share your email with anyone else.
            </div>
          </div>
          <div class="mb-3">
            <label for="phone" class="form-label">Phone number</label>
            <input
              type="tel"
              class="form-control"
              formControlName="phone"
              name="phone"
            />
            <div
              *ngIf="
                jobForm.get('phone').errors && jobForm.get('phone').touched
              "
              class="invalid"
            >
              <p *ngIf="jobForm.get('phone').hasError('required')">
                Required Field
              </p>
              <p
                *ngIf="
                  jobForm.get('phone').errors?.pattern ||
                  jobForm.get('phone').hasError('minlength') ||
                  jobForm.get('phone').hasError('maxlength')
                "
              >
                Invalid Phone
              </p>
            </div>
          </div>
          <div class="mb-3">
            <label for="age" class="form-label">Age</label>
            <input
              [(ngModel)]="age"
              placeholder=""
              type="number"
              class="form-control"
              max="40"
              min="18"
              formControlName="age"
              name="age"
            />
            <p *ngIf="age < 17" style="color: red">Age not allowed</p>
            <div
              *ngIf="jobForm.get('age').errors && jobForm.get('age').touched"
              class="invalid"
            >
              <p *ngIf="jobForm.get('age').hasError('required')">
                Required Field
              </p>
            </div>
          </div>
          <button
            type="submit"
            id="submit"
            class="btn btn-primary"
            style="width: 100%; font-family: 'Fredoka One'"
            [disabled]="!status"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<!--Footer-->
<app-footer [screen]="3"></app-footer>
