<!--Header-->
<app-header></app-header>
<!--Home-->
<div class="container col-lg-8">
  <h1 class="text-lg-center">Let Us Help Your Child Discover THEIR World!!!</h1>
</div>
<div id="content-img">
  <img src="assets/home/kids.png" class="d-block mx-auto" id="img" alt="" />
</div>
<div id="content">
  <div class="container">
    <div class="row col-lg offset-lg-1" id="content-welcome">
      <div class="col-lg">
        <p class="title">WELCOME.</p>
        <p id="text">
          Children's World Child Development Center has been teaching and caring
          for children over 20 years. They offer full days (6am-6pm) of the New
          Mexico State-funded Pre-K Program for children ages 4-5! Their mission
          is to provide a safe environment for children to grow and enables
          parents to thrive at work! BBB member Angie's List Licensed & Insured
          Owned & operated with 20 years of experience
        </p>
      </div>
      <div class="col-lg">
        <img
          src="assets/home/logo.png"
          class="d-block mx-auto"
          alt=""
          id="img-logo"
        />
        <a routerLink='/About-us'
          ><button
            id="btn"
            type="button"
            class="btn btn-primary d-block mx-auto"
          >
            More about us
          </button></a
        >
      </div>
    </div>
  </div>
  <div class="row col-12" id="content-objectives">
    <div class="col-lg-2 col-2">
      <img src="assets/home/globo.png" alt="" id="globo" class="spacing" />
    </div>
    <div class="col-lg col-8 space-down">
      <p class="text-center" id="title-objectives">MAIN OBJECTIVES.</p>
    </div>
    <div class="col-lg-2 col-2">
      <img
        src="assets/home/globo.png"
        class="rota-horizontal spacing"
        alt=""
        id="globo"
      />
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div
        class="col-lg-4 blob-spacing-down spacing-between"
        style="height: 350px"
      >
        <div id="blob-one">
          <div class="offset-2 col-lg-9 col-9 container blob-text">
            <i
              class="bi bi-gear-fill"
              style="font-size: 100px; display: flex; justify-content: center"
            ></i>
            <p class="blob-title text-center">Developing</p>
            <p class="text-center">
              Contribute to the physical, intellectual, emotional, social and
              moral development of children.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 spacing-between">
        <div id="blob-two">
          <div class="offset-2 col-lg-9 col-9 container blob-text">
            <br />
            <i
              class="bi bi-house-door-fill"
              style="font-size: 100px; display: flex; justify-content: center"
            ></i>
            <p class="blob-title text-center">Environment</p>
            <p class="text-center">The discovery of the environment.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 blob-spacing-down spacing-between">
        <div id="blob-three">
          <div class="offset-2 col-lg-9 col-9 container blob-text">
            <br />
            <i
              class="bi bi-people-fill"
              style="font-size: 100px; display: flex; justify-content: center"
            ></i>
            <p class="blob-title text-center">Coexistence</p>
            <p class="text-center">Coexistence with others.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row spacing-blob">
      <div class="col-lg-4 spacing-between">
        <div id="blob-four">
          <div class="offset-2 col-lg-9 col-9 container blob-text">
            <i
              class="fas fa-child"
              style="font-size: 100px; display: flex; justify-content: center"
            ></i>
            <br />
            <p class="blob-title text-center">Body</p>
            <p class="text-center">Know and control your own body.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 blob-spacing-up spacing-between">
        <div id="blob-five">
          <div class="offset-2 col-lg-9 col-9 container blob-text">
            <br />
            <i
              class="bi bi-hand-thumbs-up-fill"
              style="font-size: 100px; display: flex; justify-content: center"
            ></i>
            <p class="blob-title text-center">Habits</p>
            <p class="text-center">
              Promote the acquisition of healthy lifestyle habits and autonomy.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 spacing-between">
        <div id="blob-six">
          <div class="offset-2 col-lg-9 col-9 container blob-text">
            <br />
            <i
              class="bi bi-cursor-fill"
              style="font-size: 100px; display: flex; justify-content: center"
            ></i>
            <p class="blob-title text-center">Play</p>
            <p class="text-center">The game and the movement.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="text-align: right;">
    <img
      src="assets/home/pincel.png"
      class="col-lg-4 col-12"
      alt=""
      width="450"
      style="text-align: right"
    />
  </div>
  <!--Footer-->
  <app-footer [screen]="0"></app-footer>
  <!--Footer-->
</div>




