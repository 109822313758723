<!-- Header -->
<app-header></app-header>

<!--COMMUNITY-->
<div id="divBody">
    <div id="msjDirectora"><img id="marco" src="../../../assets/community/marcoCorazonPX.png" 
                                           alt="" 
                                           width="700px" 
                                           height="500px">
                                           <p class="hi" >Thank you for consdering Children's 
                                               World Child Development Center, INC. as your child's 
                                               childcare center. We at Chidlren's World strive to provide 
                                               your child(ren) woth enriching enviroment that allows them to 
                                               "Discover their world" while providing all parents with the "peace of mind" 
                                               that is so necesary to get through our days at work I invite you personally to come 
                                               and see what Chidlren's World is all about, 
                                               stop in and ask questions, view our activities in progress and in general make 
                                               Owner of Children's World CDC. Inc." <img id="directora" src="../../../assets/community/directoraName.png" alt="Owner Rosa Barraza">
                                               of your preciuos child we take our bussiness soriously however we firmly believe 
                                               that children learn in a fun, interpretative, interactive, and stimulating enviroment, 
                                               We hope you find that here at Chidlren's World-
                                               A special thank you to all military families who help to maintain the freedoms we USA 
                                               citizens enjoy daily. Wish advance notice CWCDC will assist military families with 
                                               nontraditional hours during military exercises. Aditionally, overtime late fees are 
                                               not charged to our military families actively serving our country. Many thanks for you 
                                               and your families "Sacrifice and dedication to preseving the dreedoms our country holds dear" 
                                            </p>
     </div>

     <div id="divPlane">
         <img id="plane" src="../../../assets/community/Avioneta6.png" alt="Parent´s Recommendations">
     </div>
     <div id="divComment">
         <img id="pluma" src="../../../assets/community/pluma.png" alt="">

         <div id="divCarousel">
          <div id="carouselTXT" class="carousel  slide col-auto" data-bs-ride="carousel">
            <div id="carouselComm" class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="10000">
                <p class="textCarou"> I highly recommend Children's World CDC, 
                  My son is been attending there for almost 2 years. 
                  He absolutely loves it! The staff is super nice and 
                  caring and they are always very friendly as well
                    <br><br>
                   <i>Anabella Yanez  <br> May 20<sup>th</sup> 2021</i></p>
              </div>
              <div class="carousel-item" data-bs-interval="2000">
                <p class="textCarou"> I would most definitely recommend this 
                  facility to all families. My daughter talks about how much 
                  she's learned a even comes home speaking spanish, I feel like 
                  she is better prepared for kindergarten I appreciate all the work and learning 
                  they put into my daughter.
                    <br><br>
                    <i>Amber Clayton <br> May 19<sup>th</sup> 2021</i></p>
              </div>
              <div class="carousel-item">
                <p class="textCarou"> Desde el año 2019 mis 2 hijos asisten a Children's World 
                   y yo estoy muy contento por todo lo que he aprendido. 
                   Mi hijo de 4 años a avanzado mucho en su aprendizaje y el es 
                   un niño con necesidades especiales y el a aprendido mucho.
                   Todos son muy atentos y amables al entrar y salir del centro.
                    <br><br>
                    <i>Vanessa Gonzales <br> May 19<sup>th</sup> 2021</i></p>
              </div>
              <div class="carousel-item">
                <p class="textCarou"> Mi hijo esta en Children's World 
                  CDC desde el año 2016 en la clase de niños después 
                  de la escuela me he sentido tranquila porque ha 
                  recibido ayuda en sus tareas de la escuela durante el 
                  tiempo que estuvo en la Elementary, el apoyo a sido 
                  mucho mas en estos tiempos de pandemia que sus clases 
                  que las recibió de forma virtual.
                  En su clase todos los niños son importantes y se les 
                  presta la atención que cada uno requiere.
                    <br><br>
                    <i>Miriam Baque <br> May 20<sup>th</sup> 2021</i></p>
              </div>
            </div>
            <button class="carousel-control-prev but" type="button" data-bs-target="#carouselTXT" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              
            </button>
            <button class="carousel-control-next but" type="button" data-bs-target="#carouselTXT" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
            
            </button>
          </div>
         </div>

     </div>
</div>
<!--Footer-->
<app-footer [screen]="3"></app-footer>