import { CommunityComponent } from './components/community/community.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { HomeComponent } from './components/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';

const routes: Routes = [
  { path: 'Home', component: HomeComponent},
  { path:'Contact-us', component: ContactUsComponent},
  { path: 'About-us', component: AboutUsComponent },
  { path: 'Gallery', component: GalleryComponent},
  { path:'Community', component: CommunityComponent},
  { path: 'Activities', component: ActivitiesComponent},
  {path: '', redirectTo: '/Home', pathMatch: 'full'},
  {path: '**', redirectTo: '/Home'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', anchorScrolling: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
