<!--0-->
<div *ngIf="screen == 0">
<img src="assets/footer/puzzle-piece-0.svg" alt="" id="content-img">
<div style="background-color: #C3E6F2;">
  <div class="container">
    <div class="row no-spacing">
      <div class="col-lg-4 txtCenter" style="color: #f15757; margin-top: 50px;">
        <h3 id="title">Contact</h3>
        <p>
          <i class="bi bi-geo-alt-fill"></i> 2103 E 1st Street Alamogordo, NM
          88310
        </p>
        <p>
          <i class="bi bi-telephone-fill"></i> 575-434-3150 FAX (575) 434-9515
        </p>
        <p><i class="bi bi-envelope-fill"></i> cwcdc05@gmail.com</p>
      </div>
      <div class="col-lg-2 txtCenter" style="color: #963bc0; margin-top: 50px">
        <h3 id="title">Us</h3>
        <p>
          <a routerLink='/About-us' style="text-decoration: none; color: #963bc0"
            ><i class="bi bi-question-circle-fill"></i> About Us</a
          >
        </p>

        <p>
          <a routerLink='/Gallery' style="text-decoration: none; color: #963bc0"
            ><i class="bi bi-images"></i> Gallery</a
          >
        </p>

      </div>
      <div class="col-lg-6" style="margin-top:50px;">
        <img
          class="d-lg-block ml-lg-auto"
          src="assets/footer/kids.png"
          alt=""
          style="width: 100%"
        />
      </div>
      <div style="margin-top: 30px" class="txtCenter">
        <p class="socialMedia">
          <a href="https://www.facebook.com/Childrens-World-CDC-Inc-143832222972132?_rdc=2&_rdr" target="_blank" style="text-decoration: none; color: rgb(81, 81, 253);"
            ><i class="bi bi-facebook"></i
          ></a>
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<!--1-->
<div *ngIf="screen == 1" >
<img src="assets/footer/puzzle-piece-1.svg" alt="" id="content-img">
<div style="background-color: #00284e">
  <div class="container">
    <div class="row no-spacing">
      <div class="col-lg-4 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Contact</h3>
        <p>
          <i class="bi bi-geo-alt-fill"></i> 2103 E 1st Street Alamogordo, NM
          88310
        </p>
        <p>
          <i class="bi bi-telephone-fill"></i> 575-434-3150 FAX (575) 434-9515
        </p>
        <p><i class="bi bi-envelope-fill"></i> cwcdc05@gmail.com</p>
      </div>
      <div class="col-lg-2 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Us</h3>
        <p>
          <a routerLink='/About-us' style="text-decoration: none; color: #fff"
            ><i class="bi bi-question-circle-fill"></i> About Us</a
          >
        </p>

        <p>
          <a routerLink='/Gallery' style="text-decoration: none; color: #fff"
            ><i class="bi bi-images"></i> Gallery</a
          >
        </p>
      </div>
      <div class="col-lg-6" style="margin-top: 50px">
        <img
          class="d-lg-block ml-lg-auto"
          src="assets/footer/train.png"
          alt=""
          style="width: 100%"
        />
      </div>
      <div style="margin-top: 30px" class="txtCenter">
        <p class="socialMedia">

          <a href="https://www.facebook.com/Childrens-World-CDC-Inc-143832222972132?_rdc=2&_rdr" target="_blank" style="text-decoration: none; color: white"
            ><i class="bi bi-facebook"></i
          ></a>
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<!--2-->
<div *ngIf="screen == 2">
  <img src="assets/footer/puzzle-piece-2.svg" alt="" id="content-img">

<div  style="background-color: #b153c4">
  <div class="container">
    <div class="row no-spacing">
      <div class="col-lg-4 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Contact</h3>
        <p>
          <i class="bi bi-geo-alt-fill"></i> 2103 E 1st Street Alamogordo, NM
          88310
        </p>
        <p>
          <i class="bi bi-telephone-fill"></i> 575-434-3150 FAX (575) 434-9515
        </p>
        <p><i class="bi bi-envelope-fill"></i> cwcdc05@gmail.com</p>
      </div>
      <div class="col-lg-2 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Us</h3>
        <p>
          <a routerLink='/About-us' style="text-decoration: none; color: #fff"
            ><i class="bi bi-question-circle-fill"></i> About Us</a
          >
        </p>

        <p>
          <a routerLink='/Gallery' style="text-decoration: none; color: #fff"
            ><i class="bi bi-images"></i> Gallery</a
          >
        </p>
      </div>
      <div class="col-lg-6" style="margin-top: 50px">
        <img
          class="d-lg-block ml-lg-auto"
          src="assets/footer/train.png"
          alt=""
          style="width: 100%"
        />
      </div>
      <div style="margin-top: 30px" class="txtCenter">
        <p class="socialMedia">
          <a href="https://www.facebook.com/Childrens-World-CDC-Inc-143832222972132?_rdc=2&_rdr" target="_blank" style="text-decoration: none; color: white"
            ><i class="bi bi-facebook"></i
          ></a>
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<!--3-->
<div *ngIf="screen == 3">
  <img src="assets/footer/puzzle-piece-3.svg" alt="" id="content-img">

<div  style="background-color: #2195f7">
  <div class="container">
    <div class="row no-spacing">
      <div class="col-lg-4 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Contact</h3>
        <p>
          <i class="bi bi-geo-alt-fill"></i> 2103 E 1st Street Alamogordo, NM
          88310
        </p>
        <p>
          <i class="bi bi-telephone-fill"></i> 575-434-3150 FAX (575) 434-9515
        </p>
        <p><i class="bi bi-envelope-fill"></i> cwcdc05@gmail.com</p>
      </div>
      <div class="col-lg-2 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Us</h3>
        <p>
          <a routerLink='/About-us' style="text-decoration: none; color: #fff"
            ><i class="bi bi-question-circle-fill"></i> About Us</a
          >
        </p>
        <p>
          <a routerLink='/Gallery' style="text-decoration: none; color: #fff"
            ><i class="bi bi-images"></i> Gallery</a
          >
        </p>

      </div>
      <div class="col-lg-6" style="margin-top: 50px">
        <img
          class="d-lg-block ml-lg-auto"
          src="assets/footer/train.png"
          alt=""
          style="width: 100%"
        />
      </div>
      <div style="margin-top: 30px" class="txtCenter">
        <p class="socialMedia">

          <a href="https://www.facebook.com/Childrens-World-CDC-Inc-143832222972132?_rdc=2&_rdr" target="_blank" style="text-decoration: none; color: white"
            ><i class="bi bi-facebook"></i
          ></a>
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<!--4-->
<div *ngIf="screen == 4"> 
  <img src="assets/footer/puzzle-piece-4.svg" alt="" id="content-img">

<div  style="background-color: #7066b3">
  <div class="container">
    <div class="row no-spacing">
      <div class="col-lg-4 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Contact</h3>
        <p>
          <i class="bi bi-geo-alt-fill"></i> 2103 E 1st Street Alamogordo, NM
          88310
        </p>
        <p>
          <i class="bi bi-telephone-fill"></i> 575-434-3150 FAX (575) 434-9515
        </p>
        <p><i class="bi bi-envelope-fill"></i> cwcdc05@gmail.com</p>
      </div>
      <div class="col-lg-2 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Us</h3>
        <p>
          <a routerLink='/About-us' style="text-decoration: none; color: #fff"
            ><i class="bi bi-question-circle-fill"></i> About Us</a
          >
        </p>

        <p>
          <a routerLink='/Gallery' style="text-decoration: none; color: #fff"
            ><i class="bi bi-images"></i> Gallery</a
          >
        </p>

      </div>
      <div class="col-lg-6" style="margin-top: 50px">
        <img
          class="d-lg-block ml-lg-auto"
          src="assets/footer/train.png"
          alt=""
          style="width: 100%"
        />
      </div>
      <div style="margin-top: 30px" class="txtCenter">
        <p class="socialMedia">
          <a href="https://www.facebook.com/Childrens-World-CDC-Inc-143832222972132?_rdc=2&_rdr" target="_blank" style="text-decoration: none; color: white"
            ><i class="bi bi-facebook"></i
          ></a>
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<!--5-->
<div *ngIf="screen == 5">
  <img src="assets/footer/puzzle-piece-5.svg" alt="" id="content-img">

<div  style="background-color: #006c85">
  <div class="container">
    <div class="row no-spacing">
      <div class="col-lg-4 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Contact</h3>
        <p>
          <i class="bi bi-geo-alt-fill"></i> 2103 E 1st Street Alamogordo, NM
          88310
        </p>
        <p>
          <i class="bi bi-telephone-fill"></i> 575-434-3150 FAX (575) 434-9515
        </p>
        <p><i class="bi bi-envelope-fill"></i> cwcdc05@gmail.com</p>
      </div>
      <div class="col-lg-2 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Us</h3>
        <p>
          <a routerLink='/About-us'4 style="text-decoration: none; color: #fff"
            ><i class="bi bi-question-circle-fill"></i> About Us</a
          >
        </p>

        <p>
          <a routerLink='/Gallery' style="text-decoration: none; color: #fff"
            ><i class="bi bi-images"></i> Gallery</a
          >
        </p>
        
      </div>
      <div class="col-lg-6" style="margin-top: 50px">
        <img
          class="d-lg-block ml-lg-auto"
          src="assets/footer/train.png"
          alt=""
          style="width: 100%"
        />
      </div>
      <div style="margin-top: 30px" class="txtCenter">
        <p class="socialMedia">
          <a href="https://www.facebook.com/Childrens-World-CDC-Inc-143832222972132?_rdc=2&_rdr" target="_blank" style="text-decoration: none; color: white"
            ><i class="bi bi-facebook"></i
          ></a>
        </p>
      </div>
    </div>
  </div>
</div>
</div>

<!--6-->
<div *ngIf="screen == 6"> 
  <img src="../../../assets/footer/puzzle-piece-5.svg" alt="" id="content-img">

<div  style="background-color: #006c85">
  <div class="container">
    <div class="row no-spacing">
      <div class="col-lg-4 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Contact</h3>
        <p>
          <i class="bi bi-geo-alt-fill"></i> 2103 E 1st Street Alamogordo, NM
          88310
        </p>
        <p>
          <i class="bi bi-telephone-fill"></i> 575-434-3150 FAX (575) 434-9515
        </p>
        <p><i class="bi bi-envelope-fill"></i> cwcdc05@gmail.com</p>
      </div>
      <div class="col-lg-2 txtCenter" style="color: #fff; margin-top: 50px">
        <h3 id="title">Us</h3>
        <p>
          <a routerLink='/About-us' style="text-decoration: none; color: #fff"
            ><i class="bi bi-question-circle-fill"></i> About Us</a
          >
        </p>

        <p>
          <a routerLink='/Gallery' style="text-decoration: none; color: #fff"
            ><i class="bi bi-images"></i> Gallery</a
          >
        </p>

      </div>
      <div class="col-lg-6" style="margin-top: 50px">
        <img
          class="d-lg-block ml-lg-auto"
          src="assets/footer/train.png"
          alt=""
          style="width: 100%"
        />
      </div>
      <div style="margin-top: 30px" class="txtCenter">
        <p class="socialMedia">
          <a href="https://www.facebook.com/Childrens-World-CDC-Inc-143832222972132?_rdc=2&_rdr" target="_blank" style="text-decoration: none; color: white"
            ><i class="bi bi-facebook"></i
          ></a>
        </p>
      </div>
    </div>
  </div>
</div>
</div>