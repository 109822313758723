<app-header></app-header>

<section>
  <div class="container">
    <img src="/assets/about-us/avion.png" alt="Avion dibujado" class="avion">
    <div class="row justify-content-center">
      <div class="col-4 about-us">
        <h1 class="text-center">About Us</h1>
        <p>At Children’s World Child Development Center, it is believed that children need the opportunity to explore, to experience, to play, to learn and … to succeed.  This is accomplished through a variety of activities designed to enthusiastically engage children in decision making, analyzing, comparing and by expressing their thoughts and opinions.  Through the opportunity to make choices and experience their consequences, children learn not only academically but also learn life’s social skills.   All curriculums are delivered in a dynamic, ever-changing method of play in an effort to provide children with exposure to many sensory experiences.  For example, science exploration is conducted with hands on age appropriate experiments which demonstrate a lively result/lesson; dramatic play allows children to act out a variety of scenarios and for them to experience the different viewpoints of characters firsthand.  Children’s World believes when activities are delivered to children in a non-threatening atmosphere, they can grow in their knowledge and learn that “learning” can be achieved through playful environments while reaping the reward of knowledge</p>
        <br><br><br>
        <button id="gallery" routerLink='/Gallery'>Gallery</button>
      </div>
    </div>
  </div>
</section>
<br><br><br>
<section id="mission">
  <img src="/assets/about-us/cuerda.png" alt="" id="cuerda">
  <div class="container">
    <div class="row justify-content-center content">
      <div class="col-lg-3"><div class="flag" id="purple">
        <h3 >Mission</h3>
          <div class="container">
            <p>The mission of Children's World Child Development Center is to provide services to families that create a "Peace of mind" trust aliance. This partnership allows parents to go to work and be productive while knowing their children are being provided for in a physical environment that is nurturing, strengthen families'heritage, support interactions and relationships that foster self-reflection, child friendly, safe, clean, and healthy.</p>
          </div>
      </div></div>
      <div class="col-lg-3">
        <div class="flag" id="green">
          <h3>Vision</h3>
          <div class="container">
            <p>Enabling parents to gain advocacy skills that positively impact the life of their child and where all children have access to and can partcipate in opprtunities that are both respectful of and responsive to their family experiences, culture, beliefs, abilities and circumstances</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="pasto">
  <h1>Philosophy</h1>
  <div class="container philosophy">
    <p>Children's World Child Development Center strives to help all children develop a positive self-image enviroment. Chidlren's World encourages children to be self-directed and to exhibit self-control. In order to achieve these goals, the center applies principales that buld inidividual esteem and avoids any shaming practices. as such, the center accomplishes order and discipline through close supervision, gentle guidance and redirection. In the ecen a child may become unmanageable a brief timeout may be utilized. The practice is narely employed and never overused.
      A necesary tool correct negative behavior is consistency between parent and the child's teacher. <img src="/assets/about-us/niños.png" alt="" id="niños"> Chidlren's World believes working together as a partnership is a great benefit to the child who may be having difficulties with the pressure of school and everyday life events</p>
  </div>
  <br>
  <div class="relleno">
    <app-footer [screen]=1></app-footer>
  </div>
</div>
