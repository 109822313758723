import { Component, OnInit } from '@angular/core';
import { faChild} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  icon = faChild

  constructor() { }

  ngOnInit(): void {
  }

}
